<template>
  <div>
    <Form :options="formOptions"></Form>
  </div>
</template>
<script>
import Form from '@/components/form/Form.vue'
import axios from '@/components/axios'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  data () {
    return {
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Account Group' : 'Add Account Group'
        }`,
        submitRouterPath: '/master/account',
        method: 'post',
        action: 'add',
        url: `${baseApi}/chartofaccount`,
        gridForm: true,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Name',
            required: true
          },
          {
            label: 'Parent',
            name: 'parent',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            // url: "modules",
            options: []
          }
        ]
      }
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Account Group') {
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  },
  components: {
    Form
  },
  beforeMount () {
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  },
  mounted () {}
}
</script>
